import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import LogoSvg from '../images/LPLogo.svg';
import identity from 'lodash/identity';
import { useSelector } from 'react-redux';
import useAuthUtils from '../utils/useAuthUtils';
import { NavLink } from 'react-router-dom';
import { selectSliceSessionBranding } from '../reducers/userReducer';

import './Header.less';
import { ROUTES } from '../App';

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuthUtils();
  const [configLogo, setLogo] = useState('');
  const [helpLink, setHelpLink] = useState();
  const [buttonIsOpen, setButtonIsOpen] = useState(false);
  const branding = useSelector(selectSliceSessionBranding);
  const [isLogoLoaded, setLogoLoaded] = useState(false);
  const hideLinks = /login|password|gdpr|impersonate|lock|^\/$/i.test(location.pathname);

  const applyBranding = branding => {
    const footerLinks = [
      ...((branding && branding.footerDTO && branding.footerDTO.footerLinks) || []),
      {
        text: 'Frequently Asked Questions',
        link: 'https://help.liaisonedu.com/Letters_by_Liaison_Help_Center',
      },
    ];
    const needHelpLink = footerLinks.find(v => v.text === 'Frequently Asked Questions');
    setHelpLink(needHelpLink?.link || '');
    setLogo(branding?.logo || LogoSvg);
  };

  useEffect(() => {
    applyBranding(branding);
  }, [branding]);

  const renderLinks = () => (
    <div className={hideLinks ? `navbar-section` : `navbar-section-with-links headerLogo`}>
      {auth.isAccountAuth() && auth.isPrivateRoute() && auth.account ? (
        <>
          <div className={['dropdown', buttonIsOpen ? 'show' : null].filter(identity).join(' ')}>
            <div className="dropdown-backdrop" onClick={() => setButtonIsOpen(false)}></div>
            <button
              aria-haspopup="true"
              aria-expanded={buttonIsOpen}
              id="dropdown-basic"
              type="button"
              name='account-menu'
              className="dropdown-toggle btn btn-primary lsn-user-branded font-bold mr-2"
              onClick={() => setButtonIsOpen(!buttonIsOpen)}
              title={auth.account.profile.firstName + ' ' + auth.account.profile.lastName}
            >
              <i className="fa fa-user mr-2" aria-hidden="true"></i>
              {auth.getProfileDisplayLabel()}
              <i className="fa fa-chevron-down ml-2" aria-hidden="true"></i>
            </button>
            <div
              aria-labelledby="dropdown-basic"
              className={['dropdown-menu', buttonIsOpen ? 'show' : null].filter(identity).join(' ')}
            >
              <a
                tabIndex={0}
                onKeyPress={() => {
                  setButtonIsOpen(false);
                  navigate(ROUTES.EDIT_ACCOUNT);
                }}
                onClick={() => {
                  setButtonIsOpen(false);
                  navigate(ROUTES.EDIT_ACCOUNT);
                }}
                className="dropdown-item"
                role="button"
              >
                Edit Account Info
              </a>
              <a
                tabIndex={0}
                onKeyPress={() => {
                  setButtonIsOpen(false);
                  navigate(ROUTES.DASHBOARD);
                }}
                onClick={() => {
                  setButtonIsOpen(false);
                  navigate(ROUTES.DASHBOARD);
                }}
                className="dropdown-item"
                role="button"
              >
                My Requests
              </a>
              <a
                tabIndex={0}
                onKeyPress={() => {
                  setButtonIsOpen(false);
                  navigate(ROUTES.MANAGE_DELEGATES_LIST);
                }}
                onClick={() => {
                  setButtonIsOpen(false);
                  navigate(ROUTES.MANAGE_DELEGATES_LIST);
                }}
                className="dropdown-item"
                role="button"
              >
                Manage Delegates
              </a>
              <a
                tabIndex={0}
                onKeyPress={() => {
                  setButtonIsOpen(false);
                  auth.doLogout();
                }}
                onClick={() => {
                  setButtonIsOpen(false);
                  auth.doLogout();
                }}
                className="dropdown-item"
                role="button"
              >
                Logout
              </a>
            </div>
          </div>
          <a
            tabIndex={0}
            className="nav-link lsn-nav-link-branded"
            target="_blank"
            href={helpLink}
            hidden={!helpLink}
            rel="noreferrer"
          >
            Help
          </a>
        </>
      ) : null}
      {auth.isGuestAuth() && auth.isGuestRoute() && auth.guest ? (
        <>
          {auth.guest.hasEvaluatorAccount ? (
            <a
              tabIndex={0}
              className="nav-link float-left lsn-nav-link-branded"
              onClick={() => {
                setButtonIsOpen(false);
                auth.doLogin();
              }}
            >
              Guest Login
            </a>
          ) : (
            <a
              tabIndex={0}
              className="nav-link float-left lsn-nav-link-branded"
              onClick={() => {
                setButtonIsOpen(false);
                auth.doRegister();
              }}
            >
              Guest Create Account
            </a>
          )}
          <a
            tabIndex={0}
            className="nav-link float-left lsn-nav-link-branded"
            target="_blank"
            href={helpLink}
            rel="noreferrer"
          >
            Help
          </a>
        </>
      ) : null}
      {auth.isPublicRoute() ? (
        <>
          <NavLink tabIndex={0} className="nav-link float-left lsn-nav-link-branded login-header-link" to="/login">
            Login
          </NavLink>
          <a
            tabIndex={0}
            className="nav-link float-left lsn-nav-link-branded"
            target="_blank"
            href={helpLink}
            hidden={!helpLink}
            rel="noreferrer"
          >
            Help
          </a>
        </>
      ) : null}
    </div>
  );

  const render = () => {
    return (
      <header role="banner" hidden={!isLogoLoaded}>
        <nav className="navbar">
          <div className={hideLinks ? `navbar-section` : `navbar-section-with-links headerLogo`}>
            {configLogo ? <img
              alt="Letters by Liaison logo"
              title="Letters by Liaison"
              tabIndex={0}
              src={configLogo}
              className={'c-hand'}
              width={178}
              onLoad={() => setLogoLoaded(true)}
              onClick={() => auth.isAccountAuth() ? navigate('/dashboard') : navigate('/')}
            /> : null}
          </div>
          {hideLinks ? null : renderLinks()}
        </nav>
      </header>
    );
  };

  return render();
};

export default Header;
