/**
 * @typedef TStoreSliceOfReccomendation
 * @type {{
 *  recommendation: import('./dashboardReducer').TEvaluationDetails | null
 * }}
 */

/**
 * @type {TStoreSliceOfReccomendation}
 */
const INITIAL_STATE = {
  recommendation: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SESSION_CLEAR':
      return {
        ...INITIAL_STATE,
      };
    case 'SET_ACTIVE_RECOMMENDATION':
      return {
        ...state,
        recommendation: action.payload.recommendation || null,
      };
    default: {
      return state;
    }
  }
}

/**
 *
 * @param {import('.').TStore} store
 * @returns {TStoreSliceOfReccomendation}
 */
export const selectSliceRecommendation = store => store.recommendation;

/**
 *
 * @param {import('.').TStore} store
 * @returns {TStoreSliceOfReccomendation['recommendation']}
 */
export const selectSliceRecommendationItem = store => store.recommendation.recommendation;
