// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lsn-header {
  font-family: var(--main-font-family);
  color: var(--main-text-color);
}
.lsn-header.level-1 {
  font-size: calc(var(--main-font-size-primary) * 2.2);
}
.lsn-header.level-2 {
  font-size: calc(var(--main-font-size-primary) * 1.3);
}
.lsn-header.level-3 {
  font-size: calc(var(--main-font-size-primary) * 1.2);
}
.lsn-header.level-4 {
  font-size: var(--main-font-size-primary);
}
.lsn-header.level-5 {
  font-size: calc(var(--main-font-size-primary) * 0.9);
}
.lsn-header.level-6 {
  font-size: calc(var(--main-font-size-primary) * 0.9);
}
.lsn-header.var-bold {
  font-family: var(--main-font-family-bold) !important;
  font-weight: bolder;
}
.lsn-header:is(span) {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/controls/Typography/Heading.less"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,6BAAA;AACF;AAEI;EACE,oDAAA;AAAN;AAGI;EACE,oDAAA;AADN;AAII;EACE,oDAAA;AAFN;AAMI;EACE,wCAAA;AAJN;AAQI;EACE,oDAAA;AANN;AASI;EACE,oDAAA;AAPN;AAYE;EACE,oDAAA;EACA,mBAAA;AAVJ;AAaE;EACE,cAAA;AAXJ","sourcesContent":[".lsn-header {\n  font-family: var(--main-font-family);\n  color: var(--main-text-color);\n\n  &.level {\n    &-1 {\n      font-size: calc(var(--main-font-size-primary) * 2.2);\n    }\n\n    &-2 {\n      font-size: calc(var(--main-font-size-primary) * 1.3);\n    }\n\n    &-3 {\n      font-size: calc(var(--main-font-size-primary) * 1.2);\n    }\n\n    // font size equivalent to standard text body\n    &-4 {\n      font-size: var(--main-font-size-primary);\n    }\n\n    // consider these to be as subtext\n    &-5 {\n      font-size: calc(var(--main-font-size-primary) * .9);\n    }\n\n    &-6 {\n      font-size: calc(var(--main-font-size-primary) * .9);\n    }\n  }\n\n  // just a shortcut for bold\n  &.var-bold {\n    font-family: var(--main-font-family-bold) !important;\n    font-weight: bolder;\n  }\n\n  &:is(span) {\n    display: block;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
