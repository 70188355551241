import { combineReducers } from 'redux';

import user from './userReducer';
import recommendation from './recommendationReducer';
import dashboard from './dashboardReducer';
import delegates from './delegatesReducer';
import notifications from './notificationsReducer';

/**
 * @typedef TStore
 * @type {{
 *  user:import('./userReducer').TStoreSliceOfSession,
 *  recommendation:import('./recommendationReducer').TStoreSliceOfReccomendation,
 *  dashboard:import('./dashboardReducer').TStoreSliceOfDashboard
 *  delegates:import('./delegatesReducer').TStoreSliceOfDelegates
 *  notifications:import('./notificationsReducer').TStoreSliceOfNotifications
 * }}
 */

export default combineReducers({
  user,
  recommendation,
  dashboard,
  delegates,
  notifications,
});
