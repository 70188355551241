/**
 * @typedef TNotificationItem
 * @type {{
 *  type: 'yellow'|'purple'|'green'|'red';
 *  message: string|null;
 * }}
 */

/**
 * @typedef TStoreSliceOfNotifications
 * @type {{
 *  messages: TNotificationItem[];
 * }}
 */

/**
 * @type {() => TStoreSliceOfNotifications}
 */
const INITIAL_STATE = () => ({
  messages: [],
});

const ACTIONS = {
  SESSION_CLEAR: 'SESSION_CLEAR',
  SET_MESSAGES: 'SET_MESSAGES',
  ADD_MESSAGE: 'ADD_MESSAGE',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  CLEAR_ALL: 'CLEAR_ALL',
};

/**
 * @param {{ type: keyof typeof ACTIONS; payload?: Partial<TStoreSliceOfNotifications>; }} action
 */
export default function reducer(state = INITIAL_STATE(), action) {
  switch (action.type) {
    case ACTIONS.SESSION_CLEAR:
      return {
        ...INITIAL_STATE(),
      };
    case ACTIONS.SET_MESSAGES:
      return {
        ...state,
        messages: [
          ...(action.payload?.messages ?? []),
        ]
      };
    case ACTIONS.ADD_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages,
          ...(action.payload?.messages ?? []),
        ]
      };
    case ACTIONS.CLEAR_MESSAGE:
      const messageToRemove = action.payload?.messages?.[0];
      return {
        ...state,
        messages: messageToRemove ? state.messages
          .filter(({ message, type }) => {
            return (messageToRemove.message !== message || messageToRemove.type !== type);
          }) : state.messages,
      };
    case ACTIONS.CLEAR_ALL:
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
}

/**
 *
 * @param {...TNotificationItem[]} items
 * @returns {import('redux').AnyAction}
 */
export function setNotifications(items) {
  return {
    type: ACTIONS.ADD_MESSAGE,
    payload: {
      messages: items,
    },
  };
}

/**
 *
 * @param {TNotificationItem} item
 * @returns {import('redux').AnyAction}
 */
export function addNotification(item) {
  return {
    type: ACTIONS.ADD_MESSAGE,
    payload: {
      messages: [item],
    },
  };
}

/**
 *
 * @param {TNotificationItem} item
 * @returns {import('redux').AnyAction}
 */
export function deleteNotification(item) {
  return {
    type: ACTIONS.CLEAR_MESSAGE,
    payload: {
      messages: [item],
    },
  };
}

/**
 *
 * @returns {import('redux').AnyAction}
 */
export function clearNotifications() {
  return {
    type: ACTIONS.CLEAR_ALL,
    payload: void 0,
  };
}

/**
 *
 * @param {import('.').TStore} store
 * @returns {TStoreSliceOfNotifications}
 */
export const selectSliceNotifications = store => store.notifications;
