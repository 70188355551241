/**
 * @typedef TDelegateListItem
 * @type {{
 *  id: number;
 *  emailAddress: string|null;
 *  firstName: string|null;
 *  lastName: string|null;
 *  enabled: boolean;
 *  createdDate: string|null;
 *  updatedDate: string|null;
 * }}
 */

/**
 * @typedef TStoreSliceOfDelegates
 * @type {{
 *  messages: string[];
 *  delegates: TDelegateListItem[];
 * }}
 */

/**
 * @type {() => TStoreSliceOfDelegates}
 */
const INITIAL_STATE = () => ({
  messages: [],
  delegates: []
});

const ACTIONS = {
  SESSION_CLEAR: 'SESSION_CLEAR',
  SET_DELEGATES: 'SET_DELEGATES',
};

/**
 * @param {{ type: keyof typeof ACTIONS; payload: Partial<TStoreSliceOfDelegates>; }} action
 */
export default function reducer(state = INITIAL_STATE(), action) {
  switch (action.type) {
    case ACTIONS.SESSION_CLEAR:
      return {
        ...INITIAL_STATE(),
      };
    case ACTIONS.SET_DELEGATES:
      return {
        ...state,
        delegates: action.payload.delegates ?? [],
      };
    default:
      return state;
  }
}

/**
 *
 * @param {TDelegateListItem} delegates
 * @returns {import('redux').AnyAction}
 */
export function setDelegates(delegates) {
  return {
    type: ACTIONS.SET_DELEGATES,
    payload: {
      delegates,
    },
  };
}

/**
 *
 * @param {import('.').TStore} store
 * @returns {TStoreSliceOfDelegates}
 */
export const selectSliceDelegates = store => store.delegates;
