import React, { useEffect, useState } from 'react';
import useAuthUtils from '../../../utils/useAuthUtils';
import { SESSION_ABOUT_EXPIRE_COUNTDOWN } from '../../../utils/Constants';
import BaseModal from '../BaseModal';
import moment from 'moment';
import { TextParagraph } from '../../controls/Typography/TextBody';

import './SessionTimeoutModal.less';

let totalSecondsLeft = SESSION_ABOUT_EXPIRE_COUNTDOWN;
let runnerId = null;

const SessionTimeoutModal = () => {
  const { isIdle, doLogout, continueSession } = useAuthUtils();
  const isIdleOccured = isIdle();
  const [countdownStr, setCountdownStr] = useState('');

  const onTimeOut = () => {
    doLogout();
  };

  const modalTitle = () => {
    return (
      <>
        <i className="far fa-exclamation-triangle mr-2" aria-label={`Warning`}></i>
        Your Session will time out in
        <span className={`session-timer timer`}>
          {countdownStr}
        </span>
      </>
    );
  }

  const modalBody = () => {
    return (<TextParagraph className='text-center'>
      Your session is about to time out due to <br />
      inactivity. All unsaved work will be lost.
    </TextParagraph>);
  }

  useEffect(() => {
    // Start the timer if the user is idle
    if (isIdleOccured && runnerId === null) {
      runnerId = setInterval(() => {
        totalSecondsLeft--;
        setCountdownStr(moment.utc(totalSecondsLeft * 1000).format('mm:ss'));
        if (isIdleOccured && totalSecondsLeft <= 0) {
          clearInterval(runnerId);
          onTimeOut();
          runnerId = null;
          totalSecondsLeft = SESSION_ABOUT_EXPIRE_COUNTDOWN;
        }
        if (!isIdleOccured) {
          clearInterval(runnerId);
          runnerId = null;
          totalSecondsLeft = SESSION_ABOUT_EXPIRE_COUNTDOWN;
        }
      }, 1000);
    }
    return () => {
      clearInterval(runnerId);
      runnerId = null;
      totalSecondsLeft = SESSION_ABOUT_EXPIRE_COUNTDOWN;
    }
  }, [isIdleOccured]);

  return <BaseModal
    show={isIdleOccured}
    size='small'
    title={modalTitle()}
    body={modalBody()}
    modalPrimaryButton={'Continue working'}
    onClose={continueSession}
    modalClass={'session-timeout-modal'}
  />;
};

export default SessionTimeoutModal;
