/**
 * @typedef TEvaluationRequest
 * @type {{
 *   accessedAsGuest: boolean;
 *   applicantEmail: string;
 *   applicantFirstName: string;
 *   applicantLastName: string;
 *   casId: string;
 *   casName: string;
 *   copiedFromEvaluation: number;
 *   emailAddress: string;
 *   evaluationType: string;
 *   evaluationsDisplayName: string|null;
 *   id: number;
 *   instanceId: number;
 *   message: string|null;
 *   okToContactEvaluator: boolean;
 *   organizationName: string|null;
 *   organizationTitle: string|null;
 *   phoneNumber: string;
 *   programNames: Map<string, string>;
 *   reUseForFutureEvals: boolean|null;
 *   requestOnDate: number|null;
 *   requestedByDate: string|null;
 *   requestedOnDate: string|null;
 *   responseDate: number;
 *   status: string;
 *   subject: string|null;
 *   waiveRightOfAccess: boolean;
 *   willContactEvaluator: boolean;
 *   delegated: boolean;
 *   delegationDisabled: boolean;
 *   completedBy: string|null;
 *   evaluatorName: string|null;
 *   alternateId: string|null;
 *   alternateIdLabel: string|null;
 * }}
 */

/**
 * @typedef TEvaluationBlockRubricsRatings
 * @property {number} id
 * @property {string|null} aliasText
 * @property {string|null} createdDate
 * @property {string|null} evaluationForm
 * @property {string|null} questionType
 * @property {string} ratingName
 * @property {number} sequenceNumber
 * @property {string|null} updatedDate
 */

/**
 * @typedef TEvaluationBlockQuestionItemDetails
 * @type {{
 *  aliasText: string|null;
 *  answerOption: string|null;
 *  createdDate: string|null;
 *  enabled: boolean;
 *  id: number;
 *  maxChars: number;
 *  required: boolean;
 *  sortId: number;
 *  updatedDate: string|null;
 *  commentsEnabled: boolean;
 *  commentsLabel: string|null;
 *  commentsRequired: boolean;
 * }}
 */

/**
 * @typedef {{answer?:null|string|boolean|number, comments?:null|string}} TEvaluationBlockQuestionUserAnswer
 *
 * @typedef TEvaluationBlockQuestion
 * @property {number} id
 * @property {number} questionType
 * @property {string} questionTypeValue
 * @property {string} questionText
 * @property {number} sortId
 * @property {boolean} required
 * @property {null|TEvaluationBlockQuestionItemDetails[]} evaluationQuestionDetails
 * @property {null|TEvaluationBlockQuestionUserAnswer[]} userAnswer
 * @property {string} createdDate
 * @property {string} updatedDate
 */

/**
 * @typedef TEvaluationBlock
 * @property {number} id
 * @property {string} blockName
 * @property {string} instructions
 * @property {boolean} enabled
 * @property {boolean} required
 * @property {null} evaluationFormRubricsRatings
 * @property {TEvaluationBlockQuestion[]} evaluationQuestions
 * @property {string} createdDate
 * @property {string} updatedDate
 * @property {number} sortId
 * @property {string} constantBlockName
 */

/**
 * @typedef {{
 *  id: number|string|null;
 *  fileName: string|null;
 *  fileUploadedDate: string|null;
 * }} TUploadedFile
 */

/**
 * TODO: complete where any is occurred
 * @typedef TEvaluationDetailsResponse
 * @property {number|string|null} id
 * @property {null} docType
 * @property {boolean} acceptRequest
 * @property {null} responseDate
 * @property {string} uploadFileLocation
 * @property {string} createdDate
 * @property {string} updatedDate
 * @property {number} evaluatorId
 * @property {null} fileId
 * @property {string|null} fileName
 * @property {null} message
 * @property {null} fromPortal
 * @property {null} fileData
 * @property {string} fileType
 * @property {null} comments
 * @property {string|null} fileUploadedDate
 * @property {number} evalReqID
 * @property {null} casId
 * @property {null} status
 * @property {number} evaluationQuestionId
 * @property {any[]} evaluationFormAnswers
 * @property {any[]} evaluationStatuses
 * */

/**
 * @typedef TEvaluationDetailsFormSection
 * @property {number} id
 * @property {string} instructions
 * @property {TEvaluationBlock[]} evaluationQuestionBlocks
 * @property {object} evaluationForm
 * @property {number} evaluationForm.id
 * @property {object} evaluationForm.applicationForm
 * @property {number} evaluationForm.applicationForm.id
 * @property {string} evaluationForm.applicationForm.academicYear
 * @property {boolean} evaluationForm.applicationForm.active
 * @property {string} evaluationForm.applicationForm.beginAcceptance
 * @property {string} evaluationForm.applicationForm.formId
 * @property {string} evaluationForm.applicationForm.instanceName
 * @property {string} evaluationForm.applicationForm.startTerm
 * @property {string} evaluationForm.applicationForm.submissionDeadline
 * @property {string} evaluationForm.applicationForm.createdDate
 * @property {string} evaluationForm.applicationForm.updatedDate
 * @property {string} evaluationForm.applicationForm.submissionStartDate
 * @property {number} evaluationForm.applicationForm.academicInstitutionId
 * @property {boolean} evaluationForm.applicationForm.updateCycleInfo
 * @property {null} evaluationForm.applicationForm.organizationDTOMap
 * @property {null} evaluationForm.applicationForm.questionRuleMaps
 * @property {boolean} evaluationForm.applicationForm.savedCycle
 * @property {number} evaluationForm.applicationForm.casId
 * @property {null} evaluationForm.applicationForm.applicationFormKeyDates
 * @property {null} evaluationForm.applicationForm.organizations
 * @property {null} evaluationForm.applicationForm.exceptions
 * @property {null} evaluationForm.applicationForm.cyclePromotionDetails
 * @property {null} evaluationForm.applicationForm.casApplicationVisibilityConfigs
 * @property {string} evaluationForm.name
 * @property {boolean} evaluationForm.enabled
 * @property {number} evaluationForm.programId
 * @property {string|null} evaluationForm.instructions
 * @property {string} evaluationForm.createdDate
 * @property {string} evaluationForm.updatedDate
 * @property {string} evaluationForm.evaluationType
 * @property {object} evaluationForm.programEvaluationTypes
 * @property {number} evaluationForm.programEvaluationTypes.id
 * @property {string} evaluationForm.programEvaluationTypes.createdDate
 * @property {string} evaluationForm.programEvaluationTypes.updatedDate
 * @property {string} evaluationForm.evalSelectedType
 * @property {string} createdDate
 * @property {string} updatedDate
 * 
*/

/**
 * @typedef TObservationHoursDataEval
 * @type {{
 *   "id": number,
 *   "facilityName": string|null,
 *   "facilityAddress1": string|null,
 *   "facilityAddress2": string|null,
 *   "facilityCity": "string|null",
 *   "facilityCountry": string|null,
 *   "facilityState": string|null,
 *   "facilityZip": string|null,
 *   "startDate": string|null,
 *   "endDate": string|null,
 *   "inPatientSettings": {
 *     "id": number,
 *     "sortIndex": number,
 *     "label": string|null,
 *     "hoursCompleted": number,
 *     "hoursPlanned": number
 *   }[],
 *   "outPatientSettings": {
 *     "id": number,
 *     "sortIndex": number,
 *     "label": string|null,
 *     "hoursCompleted": number,
 *     "hoursPlanned": number
 *   }[],
 *   "otherSettings": {
 *     "id": number,
 *     "sortIndex": number,
 *     "label": string|null,
 *     "hoursCompleted": number,
 *     "hoursPlanned": number
 *   }[],
 *   "recognitionTypes":
*       {
*           "id": number,
*           "code": string|null,
*           "text": string|null,
*           "sortIdx": number,
*           "standardName": string|null,
*           "selected": boolean|null
*       }[],
 *   "experienceTypes": {
*           "id": number,
*           "code": string|null,
*           "text": string|null,
*           "sortIdx": number,
*           "standardName": string|null,
*           "selected": boolean|null
*       }[]|null,
 *   "roleOfPersonShadows": {
*           "id": number,
*           "code": string|null,
*           "text": string|null,
*           "sortIdx": number,
*           "standardName": string|null,
*           "selected": boolean|null
*       }[]|null,
 *   "patientDiagnosesTypes":
 *     {
 *         "id": number,
 *         "code": string|null,
 *         "text": string|null,
 *         "sortIdx": number,
 *         "standardName": string|null,
 *         "selected": boolean|null
 *     }[],
 *   "totalHoursCompleted": number,
 *   "totalHoursPlanned": number,
 *   "supervisorFirstName": string|null,
 *   "supervisorLastName": string|null,
 *   "supervisorPhone": string|null,
 *   "supervisorEmail": string|null,
 *   "supervisorLicenseNumber": null,
 *   "supervisorState": number,
 *   "usStates":
 *     {
 *         "id": number,
 *         "code": string|null,
 *         "text": string|null,
 *         "sortIdx": number,
 *         "hidden": boolean,
 *         "isScience": boolean
 *     }[],
 *   "blocks":
 *     {
 *         "requiredApplicantTypes": null,
 *         "supplementalSectionName": null,
 *         "sequenceNumber": number,
 *         "locked": boolean,
 *         "orgLevel": boolean,
 *         "questions": [],
 *         "visibilityRules": [],
 *         "pairedConditionsRequired": {},
 *         "oneToMany": boolean,
 *         "maxRecords": number,
 *         "id": string
 *     }[],
 *   "checkApprove": boolean
 * }}
*/

/**
 * TODO: complete where any is occurred
 * @typedef TEvaluationDetails
 * @property {number} programId
 * @property {null|string} submit
 * @property {null} evaluationFormAnswers
 * @property {string} status
 * @property {number} evaluatorId
 * @property {TEvaluationRequest} recommendationRequest
 * @property {{memberName:string}[]} committeeMembers
 * @property {TObservationHoursDataEval} observationHoursDataEval
 * @property {Partial<TEvaluationDetailsResponse>|null} evaluationResponse
 * @property {TEvaluationDetailsFormSection|null} evaluationFormSection
 * @property {TEvaluationBlockRubricsRatings[]} evaluationFormRubricsRatings
 * @property {boolean} eligibleForAutoFill
 * @property {boolean} guestUser
 */

/**
 * @typedef TEvaluation
 * @type {{} & TEvaluationDetails & TEvaluationRequest }
 */

/**
 * @readonly
 * @enum {string}
 */
export const ESortDirection = {
  Ascending: 'Ascending',
  Descending: 'Descendig',
};

/**
 * @readonly
 * @enum {string}
 */
export const EEvaluationStatus = {
  accepted: 'accepted',
  completed: 'completed',
  declined: 'declined',
  expired: 'expired',
  requested: 'requested',
  resciended: 'resciended',
};

/**
 * @typedef TDataDashboard
 * @property {TEvaluationRequest[]} recommendationRequest
 * @property {Map<EEvaluationStatus, number>} statusCount
 *
 */

/**
 * @typedef TStoreSliceOfDashboard
 * @type {{
 *  isFetching: boolean;
 *  dashboardData: TDataDashboard | null;
 *  viewParams: {
 *    filterByStatus: EEvaluationStatus[];
 *    filterByEmail: string[];
 *    sortDirection: ESortDirection;
 *    selectedSort: string;
 *    allExpanded: boolean;
 *  }
 * }}
 */


/**
 * @type {TStoreSliceOfDashboard}
 */
const INITIAL_STATE = {
  isFetching: false,
  dashboardData: null,
  viewParams: {
    filterByStatus: [EEvaluationStatus.accepted, EEvaluationStatus.requested],
    filterByEmail: [],
    sortDirection: ESortDirection.Ascending,
    selectedSort: 'requestedOn',
    allExpanded: true,
  }
};

/**
 * @typedef TStoreDashboardAction
 * @property {string} type
 * @property {{
 *  dashboardData?:TDataDashboard;
 * } & Partial<TStoreSliceOfDashboard['viewParams']>} payload
 */

/**
 *
 * @param {TStoreSliceOfDashboard} state
 * @param {TStoreDashboardAction} action
 * @returns
 */
export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_DASHBOARD_VIEW_PARAMS':
      return {
        ...state,
        viewParams: {
          ...INITIAL_STATE.viewParams,
          filterByStatus: action.payload.filterByStatus ?? state.viewParams.filterByStatus ?? INITIAL_STATE.viewParams.filterByStatus,
          filterByEmail: action.payload.filterByEmail ?? state.viewParams.filterByEmail ?? INITIAL_STATE.viewParams.filterByEmail,
          sortDirection: action.payload.sortDirection ?? state.viewParams.sortDirection ?? INITIAL_STATE.viewParams.sortDirection,
          selectedSort: action.payload.selectedSort ?? state.viewParams.selectedSort ?? INITIAL_STATE.viewParams.selectedSort,
          allExpanded: action.payload.allExpanded ?? state.viewParams.allExpanded ?? INITIAL_STATE.viewParams.allExpanded,
        }
      };
    case 'SESSION_CLEAR':
      return { ...INITIAL_STATE };
    case 'CLEAR_FILTERS':
      return {
        ...state,
        viewParams: {
          ...INITIAL_STATE.viewParams,
          // by default, select all emails
          filterByEmail: ['self',
            ...Array.from(new Set(state.dashboardData?.recommendationRequest.map(({ emailAddress }) => emailAddress))),
          ]
        }
      };
    case 'SET_DASHBOARD_DATA':
      return {
        ...state,
        dashboardData: action.payload.dashboardData,
        viewParams: {
          ...state.viewParams,
          filterByEmail: !!state.viewParams.filterByEmail?.length
            ? [...state.viewParams.filterByEmail]
            : ['self',
              ...Array.from(new Set(action.payload.dashboardData?.recommendationRequest.map(({ emailAddress }) => emailAddress))),
            ]
        }
      };
    default: {
      return state;
    }
  }
}

/**
 *
 * @param {import('.').TStore} state
 * @returns {TStoreSliceOfDashboard}
 */
export const selectSliceDashboard = state => state.dashboard;
