import React from 'react';
import ImgSupportedBrowsers from '../../../images/supported_browsers.svg';
import Button from '../../controls/Button';
import BaseModal from '../BaseModal';

const LINK_TO_BROWSERS_HELP =
  'https://help.liaisonedu.com/Letters_by_Liaison_Help_Center/Troubleshooting%2F%2FFAQs/03_Technical_Requirements';

const DeprecatedBrowserModal = () => {
  const getModalBody = () => {
    return (<><p className='text-center mb-2 mt-2'>
      This browser is no longer supported.
    </p>
      <p className='text-center mb-2'>
        To continue, switch to a supported browser.
      </p>
      <p className='text-center'>
        <img
          src={ImgSupportedBrowsers}
          width={300}
          height="auto"
          title="Review Browser Information"
          alt="Review Browser Information"
        />
      </p></>);
  }
  const getModalControls = () => {
    return <Button
      primary
      size='large'
      aria-label={'Review Browser Information'}
      onClick={() => window.location.href = LINK_TO_BROWSERS_HELP}
    >
      Review Browser Information
    </Button>
  }
  return (
    <BaseModal
      show
      title={'Unsupported Browser'}
      body={getModalBody()}
      controls={getModalControls()}
    />
  );
};

export default DeprecatedBrowserModal;
