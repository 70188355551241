import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSessionAuth,
  updateSessionAccount,
  loginError,
  setSessionAttributes,
  setSessionGuest,
  sessionClear,
  setFlow,
} from '../actions/userActions';
import { useIdleTimer } from 'react-idle-timer';
import { SESSION_IDLE_TIME } from './Constants';
import { selectSliceSession } from '../reducers/userReducer';
import { apiGET, apiPOST } from './networking';
import logging from './logging';
import { ROUTES } from '../App';

const base64 = require('base-64');

let globalIdle;

/**
 * @typedef TPrimaryConfig
 * @type {{ privateRoutes?: string[]; guestRoutes?: string[]; isPrimary?:boolean; name?:string; }}
 */

/**
 * @type {TPrimaryConfig}
 */
let primaryConfig = {
  privateRoutes: [],
  guestRoutes: [],
  isPrimary: false,
  name: '-',
};

/**
 *
 * @param {TPrimaryConfig} param0
 * @returns
 */
export const configureAuth = ({ privateRoutes = [], guestRoutes = [] }) => {
  primaryConfig = {
    ...primaryConfig,
    privateRoutes,
    guestRoutes,
  };
  return primaryConfig;
};

/**
 * @typedef THookUseAuthUtils
 * @type {ReturnType<useAuthUtils>}
 */

/**
 *
 * @param {TPrimaryConfig=} props
 * @returns
 */
export const useAuthUtils = props => {
  const { isPrimary, name, privateRoutes, guestRoutes } = {
    ...primaryConfig,
    ...props,
  };

  const userStore = useSelector(selectSliceSession);
  const [auth, _setAuth] = useState(userStore.auth);
  const [account, _setAccount] = useState(userStore.account);
  const [guest, _setGuest] = useState(userStore.guest);
  const [attributes, _setAttributes] = useState(userStore.attributes);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const timeout = parseInt(sessionStorage.getItem('custom_idle_timeout') + '') || SESSION_IDLE_TIME;

  const idle = useIdleTimer({
    stopOnIdle: true,
    startManually: true,
    name: 'app_session_idle',
    timeout: isPrimary ? timeout : 1,
    element: document,
    onIdle: isPrimary
      ? () => {
        logging(`[idle:cb:${name}] isPrimary=${isPrimary}; auth=${!!auth}`).debug();
        isPrimary && auth ? setIdleMode(!!auth) : void 0;
      }
      : void 0,
    debounce: 250,
  });

  globalIdle = globalIdle || idle;

  /**
   *
   * @param {boolean} value
   */
  const setIdleMode = value => {
    dispatch(
      setSessionAttributes({
        isIdleOcurred: value,
      })
    );
  };

  /**
   *
   * @param {string[]} routePaths
   * @returns {boolean}
   */
  const isPathMatches = (routePaths = []) =>
    routePaths.reduce((r, rPath) => r || location.pathname.startsWith(rPath), false);

  const loggedInAs = () => {
    return auth && (guest || account)
      ? auth && auth.authorities && auth.authorities[0] && auth.authorities[0].authority
      : !!guest
        ? 'ROLE_CAS_GUEST_EVALUATOR'
        : null;
  };

  const isPublicRoute = () => {
    const routeNeedAccount = isPathMatches(privateRoutes);
    const routeNeedGuest = isPathMatches(guestRoutes);
    return !routeNeedAccount && !routeNeedGuest;
  };

  const isGuestRoute = () => {
    const routeNeedGuest = isPathMatches(guestRoutes);
    return routeNeedGuest;
  };

  const isPrivateRoute = () => {
    const routeNeedAccount = isPathMatches(privateRoutes);
    return routeNeedAccount;
  };

  const isUnauthorized = () => {
    return !loggedInAs();
  };

  const isGuestAuth = () => {
    return loggedInAs() === 'ROLE_CAS_GUEST_EVALUATOR' && !!guest;
  };

  const isAccountAuth = () => {
    return !!loggedInAs() && loggedInAs() !== 'ROLE_CAS_GUEST_EVALUATOR' && !!account;
  };

  useEffect(() => {
    logging('[monitor] user store updating').debug();
    const { auth, guest, attributes, account } = userStore;
    _setAccount(account);
    _setAuth(auth);
    _setGuest(guest);
    _setAttributes(attributes);
  }, [userStore]);

  useEffect(() => {
    if (!isPrimary) {
      return;
    }
    const routeNeedAccount = isPathMatches(privateRoutes);
    const routeNeedGuest = isPathMatches(guestRoutes);
    routeNeedAccount ? logging(' -> IS ROUTE PRIVATE').debug() : void 0;
    routeNeedGuest ? logging(' -> IS ROUTE GUEST').debug() : void 0;
    routeNeedGuest && routeNeedAccount ? logging(' -> AUTH REQUIRED').debug() : logging(' -> IS ROUTE PUBLIC').debug();

    logging(`[monitor] location.pathname => ${location.pathname}`).debug();
    logging(`[monitor] AuthAsAccount: ${isAccountAuth()}; AuthAsGuest: ${isGuestAuth()};`).debug();
    logging(`[monitor] attributes.authorizing => ${attributes.authorizing}`).debug();

    // if (/guestaccess|impersonateevaluator|login|register/.test(location.pathname)) {
    //   // we skip further checks because that routes
    //   // do redirects by their own
    //   return;
    // }

    // if (attributes.authorizing) {
    //   return;
    // }

    if ((routeNeedAccount && !isAccountAuth()) || (routeNeedGuest && !loggedInAs())) {
      return navigate(ROUTES.LOGIN);
    }

    // if (isAccountAuth() || isGuestAuth() || isPublicRoute()) {
    //   dispatch(setSessionAttributes({ authorizing: false }));
    // }

    // TODO: do not keep user auth, logout when login url occurs
    // if (!routeNeedGuest && !routeNeedAccount && isAccountAuth()) {
    //   console.log(' -> REDIRECT TO DASHBOARD FROM PUBLIC ROUTE');
    //   return navigate('/dashboard');
    // }

    // Redirect users from Register to Dashboard page automatically
    // if (/register/.test(location.pathname) && !routeNeedGuest && !routeNeedAccount && isAccountAuth()) {
    //   logging(' -> REDIRECT TO DASHBOARD FROM PUBLIC ROUTE').debug();
    //   return navigate(ROUTES.DASHBOARD);
    // }
  }, [location.pathname, auth, attributes.authorizing]);

  useEffect(() => {
    if (!isPrimary) {
      return;
    }
    !!auth ? continueSession() : globalIdle.reset();
  }, [auth]);

  const continueSession = () => {
    logging(`[auth:continueSession:${name}] before continueSession(); isIdle=`, globalIdle.isIdle()).debug();
    globalIdle.activate();
    globalIdle.start();
    setIdleMode(false);
    logging(`[auth:continueSession:${name}] continueSession(); isIdle=`, globalIdle.isIdle()).debug();
  };

  const continueLoggedOut = () => {
    logging(`[auth:continueLoggedOut:${name}]`).debug();
    setIdleMode(false);
  };

  const submitNewUserAndLogin = async (accountPayload) => {
    try {
      await apiPOST('/recommendation/rest/profile/userAccount', accountPayload);
      // TODO: keep it form now as-is
      const [{ auth }, { account }] = await Promise.all([fetchSessionInfo(), fetchLoggedUserAccount()]);
      dispatch(setSessionAuth(auth));
      dispatch(updateSessionAccount(account));
      return { error: null, account, message: null, }
    } catch (error) {
      throw Object.assign(new Error(error.body.messages?.pop()?.message ||
        'We were unable to process your request. Please try again later.'), {
        error,
        account: null,
      });
    } finally {

    }
  };

  const submitEditUser = async (accountPayload) => {
    try {
      const respAccountSubmit = await apiPOST('/recommendation/rest/profile/userAccount', accountPayload);
      dispatch(
        updateSessionAccount({
          ...accountPayload,
          profile: {
            ...accountPayload.profile,
            firstName: accountPayload.profile.firstName,
            lastName: accountPayload.profile.lastName,
          },
        })
      );
      return { error: null, account: respAccountSubmit.body, message: null, };
    } catch (error) {
      throw Object.assign(new Error(error.body.messages?.pop()?.message ||
        'We were unable to process your request. Please try again later.'), {
        error,
        account: null,
      });
    } finally {

    }
  };

  // store update seq.: Auth, Account
  const fetchLoggedUserAccount = async () => {
    try {
      const respAcc = await apiGET('/recommendation/rest/profile/userAccount');
      return { error: null, account: respAcc.body, message: null, };
    } catch (error) {
      throw Object.assign(new Error(error.body?.messages?.pop()?.message ||
        'We were unable to process your request. Please try again later.'), {
        error,
        account: null,
      });
    }
  };

  const fetchSessionInfo = async () => {
    try {
      const respAuth = await apiGET('/recommendation/rest/authenticatedUser');
      return { error: null, auth: respAuth.body, message: null, };
    } catch (error) {
      throw Object.assign(new Error(error.body?.messages?.pop()?.message ||
        'We were unable to process your request. Please try again later.'), {
        error,
        auth: null,
      });
    } finally { }
  }

  const shouldReAuthoriseGuest = () => {
    const { guest } = userStore;
    // grab email and evaluation uuid from the url
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const evalRequestId = Number(url.searchParams.get('evalRequestId'));

    // compare with the current logged guest details
    return guest?.evalEmailAddress !== email || guest.evalRequestId !== evalRequestId;
  }

  // store update seq.: Guest, Auth, Account
  /**
   *
   * @returns {Promise<{
   *   guest: import('../reducers/userReducer').TDataUserGuest|null,
   *   auth: import('../reducers/userReducer').TDataAuth|null,
   *   account: import('../reducers/userReducer').TDataAccount|null,
   * }|null>}
   */
  const authAsGuest = async () => {
    if (!shouldReAuthoriseGuest()) {
      return { guest, auth, account };
    }

    dispatch(sessionClear());
    dispatch(setSessionAttributes({ authorizing: true, accountLocked: false }));

    // grab email and evaluation uuid from the url
    const url = new URL(window.location.href);
    const email = url.searchParams.get('email');
    const evalRequestId = url.searchParams.get('evalRequestId');

    // reject when any info is missing
    if (!email || !evalRequestId) {
      return Promise.reject(null);
    }

    try {
      await apiPOST('/recommendation/logout');
    } catch { }

    // reset the Idle checks
    setIdleMode(false);

    try {
      const respGuestAuth = await apiPOST('/recommendation/rest/guest/authenticate', {
        email,
        evalRequestId,
      });
      // set guest info
      dispatch(setSessionGuest(respGuestAuth.body));
      // fetch authenticated session info along with a logged-in account
      const [{ auth }, { account }] = await Promise.all([fetchSessionInfo(), fetchLoggedUserAccount()]);
      dispatch(setSessionAuth(auth));
      dispatch(updateSessionAccount(account));
      return { guest: respGuestAuth.body, auth, account };
    } catch (error) {
      // Request Withdrawn cases and the others
      dispatch(setSessionGuest(error.body));
      throw error.body;
    } finally {
      dispatch(setSessionAttributes({ authorizing: false, }));
    }
  };


  // store update seq.: Guest, Auth, Account
  /**
   * @returns {Promise<{message:string|null; gdprSet?:boolean; }>}
   * @throws Error
  */
  const authAsWebAdmitToken = async () => {
    dispatch(sessionClear());
    dispatch(setSessionAttributes({ authorizing: true, accountLocked: false }));

    // grab email and evaluation uuid from the url
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    const authorization = url.searchParams.get('authorization');
    const timestamp = url.searchParams.get('timestamp');

    // reject when any info is missing
    if (!id || !authorization || !timestamp) {
      return Promise.reject({ message: 'Invalid WebAdmit token.' });
    }

    try {
      await apiPOST('/recommendation/logout');
    } catch { }

    // reset the Idle checks
    setIdleMode(false);

    try {
      const respGuestAuth = await apiPOST('/recommendation/rest/wa/authenticate', {
        id,
        authorization,
        timestamp,
      });
      // set guest info
      dispatch(setSessionGuest(respGuestAuth.body));
      // fetch authenticated session info along with a logged-in account
      const [{ auth }, { account }] = await Promise.all([fetchSessionInfo(), fetchLoggedUserAccount()]);
      dispatch(setSessionAuth(auth));
      dispatch(updateSessionAccount(account));

      // gdpr check
      try {
        const respGdpr = await apiGET('/recommendation/rest/gdpr');
        return {
          message: null,
          gdprSet: respGdpr.status === 200,
        }
      } catch (error) {
        console.error(error);
        // don't throw error here because the user is already logged in
      }

      // return the default response
      return {
        message: null,
        gdprSet: false,
      }

    } catch (error) {
      // Request Withdrawn cases and the others
      dispatch(setSessionGuest(error.body));
      throw error;
    } finally {
      dispatch(setSessionAttributes({ authorizing: false, }));
    }
  };

  /**
   *
   * @param {string} username
   * @param {string} password
   * @returns
   */
  const authAsAccount = async (username, password) => {
    dispatch(sessionClear());
    dispatch(setSessionAttributes({ authorizing: true, accountLocked: false }));

    try {
      await apiPOST('/recommendation/logout');
    } catch { }

    // reset the Idle checks
    setIdleMode(false);

    try {
      // auth with the account deets
      await apiPOST('/recommendation/rest/authenticate', null,
        {
          Authorization: 'Basic ' + base64.encode(username + ':' + password),
        });
      // fetch authenticated session info along with a logged-in account
      const [{ auth }, { account }] = await Promise.all([fetchSessionInfo(), fetchLoggedUserAccount()]);
      dispatch(setSessionAuth(auth));
      dispatch(updateSessionAccount(account));
    } catch (error) {
      if (/401/.test(error.body?.status)) {
        // account is locked
        if (error.body?.statusCode === 1) {
          dispatch(setSessionAttributes({ accountLocked: true }));
        } else {
          dispatch(loginError(error.body?.messages?.at(0).message ?? 'Unexpected error during log in. Please try again.'));
        }
      }
      throw error.body?.statusCode;
    } finally {
      dispatch(setSessionAttributes({ authorizing: false }));
    }
  };

  /**
   *
   * @param {string} token
   * @returns
   */
  const authAsImpersonate = async token => {
    dispatch(sessionClear());
    dispatch(setSessionAttributes({ authorizing: true, accountLocked: false }));

    try {
      await apiPOST('/recommendation/logout');
    } catch { }

    // reset the Idle checks
    setIdleMode(false);

    try {
      // auth with the OneTimeToken
      await apiGET(`/recommendation/rest/impersonateevaluator/${token}`);
      // fetch authenticated session info along with a logged-in account
      const [{ auth }, { account }] = await Promise.all([fetchSessionInfo(), fetchLoggedUserAccount()]);
      dispatch(setSessionAuth(auth));
      dispatch(updateSessionAccount(account));
    } catch (error) {
      console.error(error.body);
      throw error.body?.statusCode;
    } finally {
      dispatch(setSessionAttributes({ authorizing: false }));
    }
  };

  const doRegister = () => {
    navigate('/register', { replace: true });
  };

  /**
   *
   * @param {string} email
   */
  const resetPasswordByEmail = async email => {
    try {
      const resp = await apiPOST('/recommendation/rest/profile/forgotPassword', { email, });
      return resp;
    } catch (error) {
      throw Object.assign(new Error(error.body?.messages?.pop()?.message ||
        'We were unable to process your request. Please try again later.'), {
        error,
      });
    } finally { }
  };

  const doLogin = () => {
    navigate('/login');
  };

  /**
   *
   * @param {{
   *  onComplete?: () => Promise<void>|void;
   *  waitForCleanSession?: boolean;
   *  redirectOnSuccess?: string;
   * }=} options
   */
  const doLogout = async (options) => {
    const { onComplete, waitForCleanSession = false, redirectOnSuccess = '/login' } = options || {};
    const _waitEmptyAuth = (attempts = 10, delay = 200) => new Promise(async (resolve, reject) => {
      if (!auth) {
        return resolve(0);
      }
      if (attempts <= 0) {
        return reject('Unable to clear the auth session.');
      }
      await new Promise((r) => { setTimeout(r, delay); });
      try {
        await _waitEmptyAuth(--attempts, delay);
      } catch (error) {
        console.error(error);
      } finally {
        resolve(0);
      }
    });


    if (!isAccountAuth() && !isGuestAuth()) {
      dispatch(sessionClear());
      // wait for the session to be cleared if needed
      if (waitForCleanSession) {
        await _waitEmptyAuth();
      }
      // call the onComplete callback if provided
      if (typeof onComplete === 'function') {
        await onComplete();
      } else if (redirectOnSuccess) {
        // or redirect to the provided route
        navigate(redirectOnSuccess);
      }
      return Promise.resolve();
    }

    dispatch(sessionClear());
    dispatch(setSessionAttributes({ authorizing: true, accountLocked: false }));

    try {
      const respLogout = await apiPOST('/recommendation/logout');
      dispatch(sessionClear());
      // wait for the session to be cleared if needed
      if (waitForCleanSession) {
        await _waitEmptyAuth();
      }
      // call the onComplete callback if provided
      if (typeof onComplete === 'function') {
        await onComplete();
      } else if (redirectOnSuccess) {
        // or redirect to the provided route
        navigate(redirectOnSuccess);
      }
      return respLogout;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      dispatch(setSessionAttributes({ authorizing: false, }));
    }
  };

  const setAttributes = attr => {
    dispatch(setSessionAttributes(attr));
  };

  const clearAttributes = () => {
    dispatch(setSessionAttributes());
  };

  const clearGuest = () => {
    dispatch(setSessionGuest(null));
  };

  const isAuthorizing = () => {
    return attributes.authorizing === null || attributes.authorizing === true;
  };

  /**
   *
   * @param {null|import('../reducers/userReducer').TDataUserGuest} flowGuestData
   */
  const setFlowReUseEval = flowGuestData => {
    dispatch(setFlow('reUseEvalGuest', flowGuestData));
  };
  const resetFlowReUseEval = () => {
    dispatch(setFlow('reUseEvalGuest', null));
  };
  const isReUseFlowActive = () => {
    return !!userStore.flows?.reUseEvalGuest;
  };

  const getProfileDisplayLabel = () => {
    return account?.profile ? [
      account.profile.firstName.substring(0, 30),
      account.profile.firstName.length > 30 ? '...' : '',
      account.profile.lastName.substring(0, 30),
      account.profile.lastName.length > 30 ? '...' : '',
    ].join(' ') : ''
  }

  const getRegistrationInfo = async () => {
    const info = {
      emailToken: new URL(window.location.href).searchParams.get('email'),
      email: '',
      success: false,
    };
    try {
      const response = info.emailToken ? await apiPOST('/recommendation/rest/profile/decryptEmail', info.emailToken) : null;
      info.email = response?.text ?? '';
      info.success = !!info.email;
    } catch (error) {
      info.success = false;
    }
    return info;
  }

  return {
    // TODO: consider moveing this into useFlow
    flows: {
      reUseEvalGuest: {
        data: userStore.flows?.reUseEvalGuest ?? null,
        set: setFlowReUseEval,
        clear: resetFlowReUseEval,
        isActive: isReUseFlowActive,
      },
    },
    isGuestAuth,
    isAccountAuth,
    isAuthorizing,
    account,
    guest,
    auth,
    attributes,
    lastErrorMessage: userStore.message,
    setAttributes,
    clearAttributes,
    isUnauthorized,
    loggedInAs,
    isPublicRoute,
    isGuestRoute,
    isPrivateRoute,
    continueSession,
    continueLoggedOut,
    loggedOutByIdle: () => attributes.isIdleOcurred && !auth,
    isIdle: () => attributes.isIdleOcurred && !!auth,
    doLogout,
    doLogin,
    doRegister,
    resetPasswordByEmail,
    authAsGuest,
    authAsAccount,
    authAsImpersonate,
    authAsWebAdmitToken,
    clearGuest,
    isBusy: () => isAuthorizing(),
    submitNewUserAndLogin,
    submitEditUser,
    fetchLoggedUserAccount,
    getProfileDisplayLabel,
    getRegistrationInfo,
    shouldReAuthoriseGuest,
  };
};

export default useAuthUtils;

export const withAuth = Component => {
  return props => {
    const auth = useAuthUtils();
    return <Component {...props} auth={auth} />;
  };
};
