/*eslint no-unused-vars: "off"*/
const getPreferredColorScheme = (/** @type {(arg0: 'dark'|'light') => void} */ callback) => {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      callback('dark');
      return 'dark';
    } else {
      callback('light');
      return 'light';
    }
  }
  callback('light');
  return 'light';
}

// experimental dark mode global function
const ___darkmode = () => {
  // experimental dark mode
  // @lsn-primary-color: #0076C0;
  // @lsn-secondary-color: #202030;
  // @lsn-black: #fff;
  // @lsn-white: #000;
  const darkModeStyleRules = `
    :root {
      --main-primary-color: #0076C0;
      --main-secondary-color: #202030;
      --main-black: #fff;
      --main-white: #000;
    }`;
  return {
    activate: () => {
      if (document.getElementById('dark-mode')) {
        return;
      }
      const darkModeStyle = document.createElement('style');
      darkModeStyle.id = 'dark-mode';
      darkModeStyle.innerHTML = darkModeStyleRules.trim();
      document.body.appendChild(darkModeStyle);
    },
    off: () => {
      const darkModeStyle = document.getElementById('dark-mode');
      if (darkModeStyle) {
        darkModeStyle.remove();
      }
    },
  }
}

// temporary dark mode global function
global.___darkmode = ___darkmode;

export function createThemeStyleSheet(brandingSession) {
  const style = document.createElement('style');
  style.id = 'branding';

  // must be like this
  const { primaryColor, secondaryColor } = brandingSession ?? {};
  const styleRules = `
  :root {
    ${primaryColor ? `--main-primary-color: ${primaryColor}` : ''};
    ${secondaryColor ? `--main-secondary-color: ${secondaryColor}` : ''};
  }
  #root { display: block !important; }`;

  const brandingElement = document.getElementById('branding');
  if (brandingElement) {
    brandingElement.innerHTML = styleRules.trim();
  } else {
    style.innerHTML = styleRules.trim();
    document.body.appendChild(style);
  }

  // TODO: uncomment this when dark mode is ready
  // getPreferredColorScheme((colorScheme) => colorScheme === 'dark' ? ___darkmode().activate() : ___darkmode().off());

  document.body.addEventListener('keyup', function (e) {
    if (e.which === 9) {
      // on tab key press, remove no-focus-outline class (which is used to remove focus border on initial load of page).
      if (document.getElementsByClassName('no-focus-outline').length > 0) {
        document.getElementsByClassName('no-focus-outline')[0].classList.remove('no-focus-outline');
      }
    }
  });
}
