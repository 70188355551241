/**
 * @typedef TDataUserGuest
 * @type {{
 *  evalStatus: string;
 *  evalEmailAddress: string;
 *  applicantFirstName: string;
 *  applicantLastName: string;
 *  evalRequestId: number;
 *  hasEvaluatorAccount: boolean;
 *  reuseForFutureEvals: boolean|null;
 *  evaluationType: string;
 *  eligibleForAutoFill: boolean;
 *  programId: number;
 *  instanceId: number;
 *  instanceName: string;
 *  errorCode: null|string;
 * }}
 */

/**
 * @typedef TDataAuth
 * @type {{
 *  accountNonExpired: boolean;
 *  accountNonLocked: boolean;
 *  answeredExtendedProfile: boolean;
 *  appCasId: number;
 *  authorities: {authority:string}[]
 *  casid: string|null;
 *  casname: string|null;
 *  credentialsNonExpired: boolean;
 *  dataProcessed: boolean;
 *  email: string;
 *  enabled: boolean;
 *  failedAttemptCount: number;
 *  failedAttemptDate: number|string;
 *  feewaiverLabel: string|null;
 *  firstname: string;
 *  formId: number;
 *  hasAboutMe: boolean;
 *  hasPaymentHistory: boolean;
 *  hasPrograms: boolean;
 *  impersonated: boolean;
 *  instructionURL: string|null;
 *  lastname: string;
 *  permissions: []
 *  portalName: string|null;
 *  profileId: number;
 *  reAppFirstLogin: boolean;
 *  reApplicant: boolean;
 *  reApplicantPaymentHistory: boolean;
 *  showLivingProfileOptIn: boolean|null;
 *  startFreshApplication: boolean;
 *  userId: number;
 *  username: string;
 * }}
 */

/**
 * @typedef TDataAccount
 * @type {{
 *  user: TDataUser;
 *  profile: TDataProfile;
 *  birthInfo: null;
 *  email: TDataEmail;
 *  alternateId: null;
 *  telephones: TDataTelephone[];
 *  userProfessionDetail: TDataUserProfessionDetail;
 *  gdpr: null;
 * }}
 */

/**
 * @typedef TDataEmail
 * @type {{
 *  id: number;
 *  emailAddress: string;
 *  emailType: string;
 *  emailTypeId: number;
 *  profileId: number;
 *  createdDate: null;
 *  updatedDate: null;
 *  preferedEmail: boolean;
 * }}
 */

/**
 * @typedef TDataProfile
 * @type {{
 *  id: number;
 *  casid: string;
 *  title: string;
 *  firstName: string;
 *  middleName: null;
 *  lastName: string;
 *  suffix: string;
 *  nickName: null;
 *  gender: null;
 *  matrialsUnderDiffName: boolean;
 *  userPrincipalId: number;
 *  agreedPresubmissionRelease: boolean;
 *  agreedTermsOfService: boolean;
 *  emailDTO: null;
 *  textAuthorization: null;
 *  textAuthorizationResponseDate: null;
 *  initialLPInstanceId: null;
 *  createdDate: null;
 *  updatedDate: null;
 * }}
 */

/**
 * @typedef TDataTelephone
 * @type {{
 *  id: number;
 *  countryCode: number;
 *  areaCode: number;
 *  phoneNumber: string;
 *  extension: number;
 *  phoneType: null;
 *  isPreferedPhoneNumber: boolean;
 *  createdDate: number;
 *  updatedDate: number;
 *  phoneTypeId: number;
 *  preferedPhoneNumber: boolean;
 * }}
 */

/**
 * @typedef TDataUser
 * @type {{
 *  id: number;
 *  username: string;
 *  password: null;
 *  securityQuestion1: number;
 *  securityQuestion2: number;
 *  securityAnswer1: string;
 *  securityAnswer2: null;
 *  createdDate: number;
 *  updatedDate: number;
 *  lastAccessDate: number;
 *  enabled: boolean;
 *  userRoles: string[];
 *  userPermissions: any[];
 *  profileDTO: null;
 *  failedAttemptCount: number;
 *  failedAttemptDate: null;
 * }}
 */

/**
 * @typedef TDataUserProfessionDetail
 * @type {{
 *  id: number;
 *  profileId: number;
 *  organization: string;
 *  title: string;
 *  occupation: string;
 *  createdDate: Date;
 *  updatedDate: Date;
 * }}
 */

/**
 * @typedef TDataBranding
 * @type {{
 *  background: string|null;
 *  footerDTO: {footerLinks:{link:string;text:string;}[]}|null;
 *  logo: string|null;
 *  primaryColor: string|null;
 *  secondaryColor: string|null;
 *  textColor: string|null;
 * }}
 */

/**
 * @typedef TSessionAttributes
 * @type {{
 *  loading: boolean;
 *  authorizing: boolean;
 *  flowOfReuse: TDataUserGuest|null;
 *  isIdleOcurred: boolean;
 *  accountLocked: boolean;
 * }}
 */

/**
 * @typedef TStoreSliceOfSession
 * @type {{
 *  message: string;
 *  branding: TDataBranding|null;
 *  auth: TDataAuth|null;
 *  account: TDataAccount|null;
 *  guest: TDataUserGuest|null;
 *  attributes: TSessionAttributes;
 *  flows: { reUseEvalGuest: TDataUserGuest|null }
 * }}
 */

/**
 * @type {TStoreSliceOfSession}
 */
const INITIAL_STATE = {
  message: '',
  branding: null,
  auth: null,
  account: null,
  guest: null,
  attributes: {
    loading: false,
    authorizing: false,
    flowOfReuse: null,
    isIdleOcurred: false,
    accountLocked: false,
  },
  flows: {
    reUseEvalGuest: null,
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SESSION_CLEAR':
      return {
        ...state,
        message: null,
        auth: null,
        account: null,
        guest: null,
        attributes: {
          ...INITIAL_STATE.attributes,
        },
      };
    case 'SESSION_GUEST':
      return {
        ...state,
        message: null,
        guest: action.payload
          ? {
            ...action.payload,
          }
          : null,
      };
    case 'SESSION_ATTRIBUTES':
      return {
        ...state,
        attributes: {
          ...state.attributes,
          ...(action.payload || INITIAL_STATE.attributes),
        },
      };
    case 'FLOW_SET':
      return {
        ...state,
        flows: {
          ...state.flows,
          ...(action.payload || {}),
        },
      };
    case 'APP_BRANDING_FULFILLED':
      return {
        ...state,
        branding: action.payload,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        message: null,
        auth: action.payload
          ? {
            ...action.payload,
          }
          : null,
      };
    case 'UPDATE_ACCOUNT':
      return {
        ...state,
        account:
          action.payload === null
            ? null
            : {
              ...(state.account || {}),
              ...(action.payload || {}),
            },
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        auth: null,
        account: null,
        guest: null,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
}

/**
 *
 * @param {import('.').TStore} store
 * @returns {TStoreSliceOfSession}
 */
export const selectSliceSession = store => store.user;

/**
 *
 * @param {import('.').TStore} store
 * @returns {TStoreSliceOfSession['branding']}
 */
export const selectSliceSessionBranding = store => store.user.branding;
