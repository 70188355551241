// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  padding: 1rem 0;
  text-align: center;
  background-color: var(--main-footer-color);
}
footer .nav-link {
  margin-right: 12px;
}
footer .lsn-nav-link-branded {
  font-size: var(--main-font-size-links);
}
#copyright .nav-link {
  margin-right: 5px;
}
#copyright .nav-link:after {
  content: ' |';
  color: black;
}
#copyright .nav-link:last-child:after {
  content: '';
}
`, "",{"version":3,"sources":["webpack://./src/layout/Footer.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,0CAAA;AACF;AAJA;EAMI,kBAAA;AACJ;AAPA;EAUI,sCAAA;AAAJ;AAIA;EACE,iBAAA;AAFF;AAIE;EACE,aAAA;EACA,YAAA;AAFJ;AAKE;EACE,WAAA;AAHJ","sourcesContent":["footer {\n  padding: 1rem 0;\n  text-align: center;\n  background-color: var(--main-footer-color);\n\n  .nav-link {\n    margin-right: 12px;\n  }\n\n  .lsn-nav-link-branded {\n    font-size: var(--main-font-size-links);\n  }\n}\n\n#copyright .nav-link {\n  margin-right: 5px;\n\n  &:after {\n    content: ' |';\n    color: black;\n  }\n\n  &:last-child:after {\n    content: ''\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
