import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TextParagraph } from '../components/controls/Typography/TextBody';
import { selectSliceSession } from '../reducers/userReducer';
import './Footer.less';

const defaultLinks = [
  {
    link: 'https://help.liaisonedu.com/Letters_by_Liaison_Help_Center/Troubleshooting%2F%2FFAQs/Privacy_Policy',
    text: 'Privacy Policy',
  },
  {
    link: 'https://www.liaisonedu.com/508-compliance/',
    text: 'Accessibility Statement',
  },
];

export const Footer = () => {
  const session = useSelector(selectSliceSession);
  const [copyrightDisplayed, setCopyrightDisplayed] = useState(false);
  const [footerLinks, setFooterLinks] = useState(defaultLinks);

  useEffect(() => {
    setFooterLinks(session.branding?.footerDTO?.footerLinks || defaultLinks);
    setCopyrightDisplayed(!session.branding?.footerDTO);
  }, [session.branding]);

  return (
    <footer role="contentinfo">
      <section id="copyright" className="grid-footer container grid-960">
        <TextParagraph hidden={!copyrightDisplayed}>© {new Date().getFullYear()} Liaison International. All Rights Reserved</TextParagraph>
        <TextParagraph>
          {footerLinks.map((footerLink, index) => (
            <a className="nav-link" key={index} href={footerLink.link} target="_blank" rel="noreferrer noopener">
              {footerLink.text}
            </a>
          ))}
        </TextParagraph>
      </section>
    </footer>
  );
};

export default Footer;
