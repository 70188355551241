import React, { Fragment, useEffect } from 'react';
import FocusTrap from 'focus-trap-react';
import uniqueId from 'lodash/uniqueId';
import { createPortal } from 'react-dom';
import Button from '../../controls/Button';
import Heading from '../../controls/Typography/Heading';
import BusyCover from '../../controls/BusyCover/BusyCover';

import './BaseModal.less';

/**
 *
 * @param {{
 *  show?: boolean,
 *  isBusy?: boolean,
 *  modalClass?: string,
 *  modalContainerClass?: string,
 *  modalHeaderClass?: string,
 *  modalBodyClass?: string,
 *  modalFooterClass?: string,
 *  size?: 'standard'|'small'|'compact',
 *  ariaLabel?: string,
 *  title?: React.ReactElement|string,
 *  subtitle?: React.ReactElement|string,
 *  body?: React.ReactElement|string,
 *  footer?: React.ReactElement|string,
 *  controls?: React.ReactElement|string,
 *  modalSecondaryButton?: string;
 *  modalPrimaryButton?: string
 *  closeIcon?: boolean,
 *  onClose?(r:boolean):void;
 *  initialFocus?: HTMLElement | SVGElement | string | (()=>HTMLElement | SVGElement | string | false) | false;
 *  hideFooter?: boolean;
 * }} props
 * @returns {null|React.ReactPortal}
 */
export const BaseModal = (props) => {
  const {
    show = false,
    modalClass = '',
    modalContainerClass = '',
    modalHeaderClass = '',
    modalBodyClass = '',
    modalFooterClass = '',
    size = 'standard',
    title = null,
    subtitle = null,
    body = null,
    footer = null,
    controls = null,
    ariaLabel,
    closeIcon,
    isBusy,
    onClose,
    modalSecondaryButton,
    modalPrimaryButton,
    initialFocus,
    hideFooter = false,
  } = props;

  const modalUid = uniqueId('lsn_modal_');
  const uuidTitle = uniqueId('_dialog_title');
  const uuidBody = uniqueId('_dialog_body');

  const hasControls = controls || modalSecondaryButton || modalPrimaryButton;

  const getCloseIcon = () => {
    return <Button
      type="button"
      iconOnly
      className='modal-close'
      aria-label={`${ariaLabel ?? title} Close Button`}
      onClick={() => onClose?.(false)}
    ><i className='fa fa-lg fa-times'></i></Button>
  }

  const handlePrimaryClick = () => {
    onClose?.(true);
  };

  const handleSecondaryClick = () => {
    onClose?.(false);
  };

  const renderControls = () => {
    const result = [
      modalSecondaryButton ? <Button
        key={`${modalUid}_control_button_secondary`}
        id={`${modalUid}_control_button_secondary`}
        secondary
        outline
        size='large'
        onClick={handleSecondaryClick}
      >
        {modalSecondaryButton}
      </Button> : null,
      modalPrimaryButton ? <Button
        key={`${modalUid}_control_button_primary`}
        id={`${modalUid}_control_button_primary`}
        hidden={!modalPrimaryButton}
        primary
        size='large'
        onClick={handlePrimaryClick}
      >
        {modalPrimaryButton}
      </Button> : null,
      <Fragment key={`_modal_control_outside`}>{controls}</Fragment>,
    ];
    return result;
  };


  const getTitleText = (/** @type {string|React.ReactNode} */title) => {
    if (typeof title === 'string') {
      return title;
    }
    if (React.isValidElement(title)) {
      return [...(title.props.children ?? [])]
        .reduce((acc, child) => [...acc, getTitleText(child)?.trim()], [])
        .filter(child => typeof child === 'string').join('');
    }
    return null;
  }

  /**
   *
   * @returns {React.ReactNode}
   */
  const renderModal = () => {
    return <FocusTrap
      focusTrapOptions={{
        initialFocus,
        fallbackFocus: () => document.querySelector(`#${modalUid} [tabindex]:not([tabindex="-1"])`) ?? document.body,
        escapeDeactivates: false,
      }}
    >
      <div className={`modal active ${modalClass} modal-size-${size}`} role="dialog" id={modalUid} title={`Modal of ${getTitleText(title) ?? 'Unknown'}`}>
        <div className="modal-overlay" />
        <div className={`modal-container ${modalContainerClass}`}>
          <BusyCover isBusy={isBusy} />
          <div className={`modal-header ${modalHeaderClass}`} id={uuidTitle}>
            {closeIcon ? getCloseIcon() : null}
            <Heading className='modal-title' asSpan bold level={2}>
              {title}
            </Heading>
            {subtitle ? <p className='modal-subtitle'>{subtitle}</p> : null}
          </div>
          <div className={`modal-body ${modalBodyClass}`} id={uuidBody}>{body}</div>
          <div className={`modal-footer ${modalFooterClass}`} hidden={hideFooter}>
            {footer}
            {hasControls ? <div className="modal-controls">
              {renderControls()}
            </div> : void 0}
          </div>
        </div>
      </div></FocusTrap>;
  }

  useEffect(() => {
    show ? setTimeout(() => {
      // focus the first active focusable element
      document.querySelector(`#${modalUid} [tabindex]:not([tabindex="-1"])`)?.focus();
    }) : void 0;
  }, [show]);

  return show ? createPortal(renderModal(), document.body) : null;
}

export default BaseModal;
