import React from 'react';
import identity from 'lodash/identity';
import { useNavigate } from 'react-router-dom';

import './Button.less';

/**
 *
 * @param {{
 * primary?:boolean;
 * secondary?:boolean;
 * danger?:boolean;
 * submit?:boolean;
 * ghost?:boolean;
 * onClick?(event:React.MouseEvent<HTMLButtonElement>):any;
 * children?:(string|React.ReactElement)|(string|React.ReactElement)[];
 * isBusy?:boolean;
 * disabled?:boolean;
 * outline?:boolean;
 * link?:string|boolean;
 * iconOnly?:boolean;
 * icon?:React.ReactElement;
 * tooltip?:string;
 * size?:'small'|'large';
 * className?:string;
 * } & import('react').ButtonHTMLAttributes} params
 * @returns {React.ReactElement|null}
 */
export const Button = (params) => {
  const {
    children,
    primary,
    secondary,
    danger,
    ghost,
    isBusy,
    submit,
    onClick,
    size,
    disabled,
    outline,
    tooltip,
    className,
    link,
    icon,
    iconOnly,
    hidden,
    ...rest
  } = params;

  const isIconOnly = typeof iconOnly !== 'undefined';
  const isLink = typeof link !== 'undefined';
  const buttonType = isLink ? 'button' : submit ? 'submit' : rest.type;
  const classNames = [
    'lsn-new-button',
    `lsn-new-button-${buttonType ?? 'untyped'}`,
    size,
    // buttons types
    isLink ? 'as-link' : '',
    isIconOnly ? 'as-icon' : '',
    !isLink && !isIconOnly ? 'as-button' : '',
    // button types
    primary ? 'type-primary' : '',
    secondary && !primary ? 'type-secondary' : '',
    danger ? 'type-danger' : '',
    // variants
    disabled ? 'var-disabled' : '',
    outline ? 'var-outline' : '',
    ghost ? 'var-ghost' : '',
    // states
    isBusy ? 'busy' : '',
    tooltip ? 'has-tooltip' : '',
    className,
  ].filter(identity).join(' ');

  const navigate = useNavigate();

  const extraProps = {
    'data-tooltip': tooltip,
  };

  const onClickProxy = (/** @type {React.MouseEvent<HTMLButtonElement>} */ event) => {
    const result = onClick?.(event);
    if (isLink && result !== false && typeof link === 'string') {
      /^(http|https):\/\//.test(link) ? window.location.href = link : navigate(link);
    }
  }

  return hidden ? null : <button {...rest} onClick={onClickProxy} className={classNames} disabled={disabled || isBusy} {...extraProps} role={isLink ? 'link' : rest.role ?? 'button'} type={buttonType} tabIndex={0}>
    {isBusy ? <span className='busy-animation'></span> : null}
    {icon ? <span className='button-inner-icon button-inner-left-icon'>{icon}</span> : null}
    {children}
    {tooltip ? <span className='tooltip-content' role={'none'}>{tooltip}</span> : null}
  </button>
}


export default Button;
