import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import rootReducer from './reducers';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { IS_JEST } from './utils/Constants';

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  inboundState => {
    // convert mySet to an Array.
    return { ...inboundState };
  },
  // transform state being rehydrated
  outboundState => {
    // convert mySet back to a Set.
    return {
      ...outboundState,
      attributes: {
        ...(outboundState.attributes || {}),
        authorizing: null,
      },
      // keep messages empty
      messages: [],
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['user'] }
);

/** @type {import('redux-persist').PersistConfig} */
const persistConfig = {
  key: 'root',
  storage,
  transforms: [SetTransform],
  throttle: 2000,
};

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);
  middlewares.push(logger);
}

middlewares.push(promise);
middlewares.push(thunk);

// we don't want to use redux-state-sync in jest tests
if (!IS_JEST) {
  // https://www.npmjs.com/package/redux-state-sync#before-you-use
  middlewares.push(createStateSyncMiddleware({
    blacklist: [
      // https://github.com/rt2zz/redux-persist/blob/master/src/constants.ts
      'persist/FLUSH',
      'persist/REHYDRATE',
      'persist/PAUSE',
      'persist/PERSIST',
      'persist/PURGE',
      'persist/REGISTER',
    ],
    channel: 'liaison_letters_portal_channel',
  }));
}

const middleware = applyMiddleware(...middlewares);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeEnhancers(middleware));
export const persistor = persistStore(store);

export default store;

// this is used to pass store.dispatch to the message listener
initMessageListener(store);
