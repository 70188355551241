import React, { useEffect } from 'react';

import ReactGA4 from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Footer, Header } from './';

import { getBranding } from '../actions/userActions';
import { selectSliceSessionBranding } from '../reducers/userReducer';
import DeprecatedBrowserModal from '../components/modals/DeprecatedBrowserModal';
import SessionTimeoutModal from '../components/modals/SessionTimeoutModal';
import { createThemeStyleSheet } from '../utils/branding';
import { useView } from '../utils/useView';
import useAuthUtils from '../utils/useAuthUtils';
import { apiGET } from '../utils/networking';

export const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const branding = useSelector(selectSliceSessionBranding);
  const location = useLocation();
  const { isViewDeprecated } = useView();
  const { pathParam1, pathParam2 } = useParams();
  const auth = useAuthUtils({
    name: 'root',
    isPrimary: true,
  });

  const googleAnalytics = () => {
    apiGET('/recommendation/rest/googleAnalytics')
      .then(response => {
        if (response && response.status === 200) {
          if (response.body.ga4TrackerCode) {
            ReactGA4.initialize(response.body.ga4TrackerCode)
          }
        }
      })
  };

  const prepareBranding = () => {
    auth.setAttributes({ loading: true });
    if (pathParam1 && pathParam2) {
      apiGET('/recommendation/rest/initialize/' + pathParam1 + '/' + pathParam2)
        .then((response) => {
          auth.setAttributes({ loading: false });
          if (response && response.status === 200) {
            dispatch(getBranding());
          }
        });
    } else {
      dispatch(getBranding());
      auth.setAttributes({ loading: false });
    }
  };

  const renderDeprecatedView = () => <DeprecatedBrowserModal />

  useEffect(() => {
    googleAnalytics();
    prepareBranding();
  }, []);

  useEffect(() => {
    createThemeStyleSheet(branding);
  }, [branding]);

  useEffect(() => {
    setTimeout(() => {
      const initFocusEl = (/** @type {HTMLDivElement=} */(document.querySelector('.no-focus-outline')));
      initFocusEl?.focus();
    }, 0);
  }, [location.pathname]);

  useEffect(() => {
    isViewDeprecated() && !location.pathname.endsWith('login') ? navigate('/login') : void 0;
  }, [location]);

  return (
    <React.Fragment>
      {isViewDeprecated() ? renderDeprecatedView() : void 0}
      <Header />
      <Outlet />
      <Footer />
      <SessionTimeoutModal />
    </React.Fragment>
  );
};

export default Main;
