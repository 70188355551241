import React from 'react';
import uniqueId from 'lodash/uniqueId';
import useView from '../../../utils/useView';
import { useState } from 'react';

import './Heading.less';

/**
 *
 * @param {{
*   id?: string;
*   level?: 1|2|3|4|5|6;
*   children?: string|React.ReactNode;
*   bold?: boolean;
*   asSpan?: boolean;
*   className?: string;
 * } & import('react').HTMLAttributes} props
 * @returns {React.ReactElement}
 */
export const Heading = (props) => {
  const { id, children, asSpan, level = 1, bold, className, ...rest } = props;
  const { classNames } = useView();
  const [controlUID] = useState(id ?? uniqueId(`lsn_ctrl_`));

  const elementProps = {
    id: controlUID,
    className: classNames(`lsn-header level-${level} var-${bold ? 'bold' : 'normal'}`, className),
    ...rest,
  };

  return React.createElement(asSpan ? 'span' : `h${level}`, elementProps, children);
}

export default Heading;
