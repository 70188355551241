export function setSessionAuth(payload) {
  return {
    type: 'LOGIN_SUCCESS',
    payload,
  };
}

export function updateSessionAccount(payload) {
  return {
    type: 'UPDATE_ACCOUNT',
    payload,
  };
}

/**
 *
 * @param {string} message
 * @returns
 */
export function loginError(message) {
  return {
    type: 'LOGIN_FAILURE',
    payload: {
      message: message,
    },
  };
}

export function sessionClear() {
  return {
    type: 'SESSION_CLEAR',
  };
}

export function storeBranding(payload) {
  return {
    type: 'APP_BRANDING',
    payload,
  };
}

/**
 *
 * @param {Partial<import('../reducers/userReducer').TSessionAttributes>=} payload
 * @returns
 */
export function setSessionAttributes(payload) {
  return {
    type: 'SESSION_ATTRIBUTES',
    payload,
  };
}

export function setSessionGuest(payload) {
  return {
    type: 'SESSION_GUEST',
    payload,
  };
}

/**
 *
 * @param {string} flowName
 * @param {any} flowData
 * @returns
 */
export function setFlow(flowName, flowData) {
  return {
    type: 'FLOW_SET',
    payload: {
      [flowName]: flowData,
    },
  };
}

export function getBranding() {
  return {
    type: 'APP_BRANDING',
    payload: fetch('/recommendation/rest/branding').then(r => r.json()),
  };
}
