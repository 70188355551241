import { isIE } from 'react-device-detect';

export const useView = () => {
  const isViewDeprecated = () => isIE;
  return {
    isViewDeprecated,
    /**
     * @param {?string=} questionText
     */
    createMarkup(questionText) {
      return {
        __html: questionText,
      };
    },
    onEnterKey: (/** @type {React.KeyboardEventHandler} */ callback) => (/** @type {React.KeyboardEvent} */ event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        callback(event);
      }
    },
    onEscapeKey: (/** @type {React.KeyboardEventHandler} */ callback) => (/** @type {React.KeyboardEvent} */ event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        callback(event);
      }
    },
    classNames: (/** @type {(string|number|null|void)[]} */ ...classes) => {
      return classes.filter(Boolean).join(' ');
    },
  };
};

export default useView;
