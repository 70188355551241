// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lsn-text-body {
  font-family: var(--main-font-family);
  color: var(--main-text-color);
  font-size: var(--main-font-size-primary);
  word-wrap: break-word;
  word-break: break-word;
  line-height: calc(var(--main-font-size-primary) * 1.2);
}
.lsn-text-body b,
.lsn-text-body strong {
  font-family: var(--main-font-family-bold);
  font-weight: bolder;
}
.lsn-text-body.var-bold {
  font-family: var(--main-font-family-bold);
  font-weight: bolder;
}
.lsn-text-body.var-larger {
  font-size: calc(var(--main-font-size-primary) * 1.2);
}
.lsn-text-body.var-smaller {
  font-size: calc(var(--main-font-size-primary) * 0.7);
}
.lsn-text-body.var-badge {
  background-color: color-mix(in srgb, var(--main-primary-color) 100%, transparent);
  color: white;
  padding: 0.13rem 0.3rem 0;
  border-radius: var(--main-control-border-radius);
}
.lsn-text-body.var-reset *:not(a) {
  all: revert;
}
`, "",{"version":3,"sources":["webpack://./src/components/controls/Typography/TextBody.less"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,6BAAA;EACA,wCAAA;EACA,qBAAA;EACA,sBAAA;EACA,sDAAA;AACF;AAPA;;EAUI,yCAAA;EACA,mBAAA;AACJ;AAEE;EACE,yCAAA;EACA,mBAAA;AAAJ;AAGE;EACE,oDAAA;AADJ;AAIE;EACE,oDAAA;AAFJ;AAKE;EACE,iFAAA;EACA,YAAA;EACA,yBAAA;EACA,gDAAA;AAHJ;AAME;EAII,WAAA;AAPN","sourcesContent":[".lsn-text-body {\n  font-family: var(--main-font-family);\n  color: var(--main-text-color);\n  font-size: var(--main-font-size-primary);\n  word-wrap: break-word;\n  word-break: break-word;\n  line-height: calc(var(--main-font-size-primary) * 1.2);\n\n  b,\n  strong {\n    font-family: var(--main-font-family-bold);\n    font-weight: bolder;\n  }\n\n  &.var-bold {\n    font-family: var(--main-font-family-bold);\n    font-weight: bolder;\n  }\n\n  &.var-larger {\n    font-size: calc(var(--main-font-size-primary) * 1.2);\n  }\n\n  &.var-smaller {\n    font-size: calc(var(--main-font-size-primary) * .7);\n  }\n\n  &.var-badge {\n    background-color: color-mix(in srgb, var(--main-primary-color) 100%, transparent);\n    color: white;\n    padding: .13rem .3rem 0;\n    border-radius: var(--main-control-border-radius);\n  }\n\n  &.var-reset {\n\n    // remove all styles from child elements except <a>\n    *:not(a) {\n      all: revert;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
