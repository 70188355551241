import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';

// import sequence maters
import './index.less';
import './fonts/fontawesome/css/fontawesome-all.min.css';
import 'spectre.css/dist/spectre.min.css';
import 'spectre.css/dist/spectre-exp.min.css';
import 'spectre.css/dist/spectre-icons.min.css';

const container = (/** @type {HTMLDivElement} */(document.getElementById('root')));
const root = createRoot(container);
root.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
