import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { configureAuth } from './utils/useAuthUtils';
import { DEV_MODE, IS_JEST } from './utils/Constants';
import Main from './layout/Main';
import BusyCover from './components/controls/BusyCover/BusyCover';

const importDelay = (importFn, delayMs = 500) =>
  async () => {
    // for testing purposes (to see the loading state of the app)
    const [result] = await Promise.all([
      importFn,
      DEV_MODE ? new Promise((resolve) => setTimeout(resolve, delayMs)) : Promise.resolve()
    ]);

    return result;
  };

const Account = React.lazy(importDelay(import('./pages/account/Account')));
const AccountLockout = React.lazy(importDelay(import('./pages/forgotPassword/AccountLockout')));
const Dashboard = React.lazy(importDelay(import('./pages/dashboard/Dashboard')));
const RecommendationForm = React.lazy(importDelay(import('./pages/recommendationForm/RecommendationForm')));
const ForgotPassword = React.lazy(importDelay(import('./pages/forgotPassword/ForgotPassword')));
const RetrievePassword = React.lazy(importDelay(import('./pages/forgotPassword/RetrievePassword')));
const ChangePassword = React.lazy(importDelay(import('./pages/forgotPassword/ChangePassword')));
const GuestAccess = React.lazy(importDelay(import('./pages/login/GuestAccess')));
const Login = React.lazy(importDelay(import('./pages/login/Login')));
const NotFound = React.lazy(importDelay(import('./pages/error/NotFound')));
const Impersonate = React.lazy(importDelay(import('./pages/login/Impersonate')));
const LoginByWebAdmitToken = React.lazy(importDelay(import('./pages/login/LoginByWebAdmitToken')));
const GDPRPage = React.lazy(importDelay(import('./pages/gdpr')));

const ErrorMessage = React.lazy(importDelay(import('./pages/success/errorMessage')));
const SuccessDeclineWithoutAcc = React.lazy(importDelay(import('./pages/success/successDeclineWithoutAcc')));
const SuccessDeclineWithAcc = React.lazy(importDelay(import('./pages/success/successDeclineWithAcc')));
const ReuseGuestAccessSubmission = React.lazy(importDelay(import('./pages/success/reuseGuestAccessSubmission')));

const ListDelegates = React.lazy(importDelay(import('./pages/manage/delegates/ListDelegates/ListDelegates')));

export const ROUTER_BASE_NAME = '/recommendation/ui';

export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  IMPERSONATE: '/impersonateevaluator',
  AUTH_WEBADMIT_TOKEN: '/waauth',
  GUEST_ACCESS: '/guestaccess',
  ACCOUNT_LOCKOUT: '/accountLocked',
  FORGOT_PASSWORD: '/forgotPassword',
  RETRIEVE_PASSWORD: '/retrievePassword',
  CHANGE_PASSWORD: '/passwordreset',
  GDPR: '/gdpr',
  EDIT_ACCOUNT: '/editAccount',
  DASHBOARD: '/dashboard',
  MANAGE: '/manage',
  MANAGE_DELEGATES_LIST: '/manage/delegates/list',
  MANAGE_DELEGATE_INVITE: '/manage/delegate/invite',
  MANAGE_DELEGATE_EDIT: '/manage/delegate/edit',
  RECOMMENDATION_FORM: '/recommendationForm',
  SUCCESS_DECLINE_WITHOUT_ACC: '/successDeclineWithoutAcc',
  SUCCESS_DECLINE_WITH_ACC: '/successDeclineWithAcc',
  REUSE_GUEST_ACCESS_SUBMISSION: '/reuseGuestAccessSubmission',
  GUEST_RECOMMENDATION_FORM: '/guestRecommendationForm',
  ERROR_MESSAGE: '/errorMessage',
  NOT_FOUND: '/notFound',
}

export const ROUTER_PATHS_PRIVATE = DEV_MODE ? [] : [
  ROUTES.DASHBOARD,
  ROUTES.EDIT_ACCOUNT,
  ROUTES.RECOMMENDATION_FORM,
  ROUTES.MANAGE_DELEGATES_LIST,
];

export const ROUTER_PATHS_GUEST = DEV_MODE ? [] : [
  ROUTES.GUEST_RECOMMENDATION_FORM,
  ROUTES.SUCCESS_DECLINE_WITHOUT_ACC,
  ROUTES.SUCCESS_DECLINE_WITH_ACC,
  ROUTES.REUSE_GUEST_ACCESS_SUBMISSION,
];

configureAuth({
  privateRoutes: ROUTER_PATHS_PRIVATE,
  guestRoutes: ROUTER_PATHS_GUEST,
});

const SuspenseLoader = (<BusyCover isBusy />);

const s = (Component, params = {}) => (
  <React.Suspense fallback={SuspenseLoader}>
    <Component {...params} />
  </React.Suspense>
);

export const ROUTER_TREE = (/** @type {import('react-router').RouteObject[]} */([
  {
    path: ROUTES.HOME,
    element: s(Main),
    errorElement: s(NotFound),
    children: [
      {
        path: '', element: s(Login),
        children: [
          {
            path: ROUTES.LOGIN, element: s(Login),
          },
          {
            path: ROUTES.LOGIN + '/:pathParam1/:pathParam2', element: s(Login),
          },
        ]
      },
      { path: ROUTES.REGISTER, element: s(Account) },
      { path: ROUTES.IMPERSONATE + '/:token', element: s(Impersonate) },
      { path: ROUTES.AUTH_WEBADMIT_TOKEN, element: s(LoginByWebAdmitToken) },
      { path: ROUTES.GUEST_ACCESS, element: s(GuestAccess), },
      { path: ROUTES.ACCOUNT_LOCKOUT, element: s(AccountLockout), },
      { path: ROUTES.FORGOT_PASSWORD, element: s(ForgotPassword), },
      { path: ROUTES.RETRIEVE_PASSWORD, element: s(RetrievePassword), },
      { path: ROUTES.CHANGE_PASSWORD + '/:token', element: s(ChangePassword), },
      { path: ROUTES.GDPR, element: s(GDPRPage), },
      { path: ROUTES.EDIT_ACCOUNT, element: s(Account, { isEdit: true }), },
      { path: ROUTES.DASHBOARD, element: s(Dashboard), },
      {
        path: ROUTES.MANAGE,
        element: s(ListDelegates),
        children: [
          {
            path: ROUTES.MANAGE_DELEGATES_LIST, element: s(ListDelegates),
          },
          // for the next sprint
          // {
          //   path: ROUTES.MANAGE_DELEGATE_INVITE, element: s(ListDelegates),
          // },
          // {
          //   path: ROUTES.MANAGE_DELEGATE_EDIT + '/:delegateId', element: s(ListDelegates),
          // },
        ]
      },
      { path: ROUTES.RECOMMENDATION_FORM + '/:formId', element: s(RecommendationForm), },
      { path: ROUTES.SUCCESS_DECLINE_WITHOUT_ACC, element: s(SuccessDeclineWithoutAcc), },
      { path: ROUTES.SUCCESS_DECLINE_WITH_ACC, element: s(SuccessDeclineWithAcc), },
      { path: ROUTES.REUSE_GUEST_ACCESS_SUBMISSION, element: s(ReuseGuestAccessSubmission), },
      { path: ROUTES.GUEST_RECOMMENDATION_FORM + '/:formId', element: s(RecommendationForm), },
      { path: ROUTES.ERROR_MESSAGE, element: s(ErrorMessage), },
      { path: ROUTES.NOT_FOUND, element: s(NotFound), },
      { path: '*', element: <Navigate to="notFound" />, }
    ]
  },
  { path: '*', element: <Navigate to={ROUTES.LOGIN} />, },
]));

const router = createBrowserRouter(ROUTER_TREE, { basename: ROUTER_BASE_NAME });

// redirect to ReactRouter base-name when the URL does not match
if (!IS_JEST && !window.location.href.includes(ROUTER_BASE_NAME) &&
  // and skip condition when we have ifram PDF preview url, it is the API url
  // hereby we omit showing the app in the iframe
  !window.location.href.includes('/recommendation/rest')) {
  window.location.href = ROUTER_BASE_NAME;
}

export const App = () => <RouterProvider router={router} ></RouterProvider>;
