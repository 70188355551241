import { IS_JEST } from './Constants';

export const logging = (/** @type {any[]} */ ...message) => {
  const skipLog = IS_JEST;// || !process.env.REACT_APP_DEBUG;

  const time = new Date().toLocaleTimeString();
  const info = () => skipLog ? void 0 : console.info(`[INFO] ${time} ${message}`);
  const warn = () => skipLog ? void 0 : console.warn(`[WARN] ${time} ${message}`);
  const error = () => skipLog ? void 0 : console.error(`[ERROR] ${time} ${message}`);
  const debug = () => skipLog ? void 0 : console.debug(`[DEBUG] ${time} ${message}`);

  return {
    info,
    warn,
    error,
    debug,
  }
};

export default logging;
