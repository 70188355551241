import React, { useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import useView from '../../../utils/useView';

import './TextBody.less';

/**
 *
 * @typedef {{
*   id?: string;
*   paragraph?: boolean;
*   children?: string|React.ReactNode;
*   bold?: boolean;
*   larger?: boolean;
*   smaller?: boolean;
*   className?: string;
 * } & import('react').HTMLAttributes} TextBodyProps
 * @param {TextBodyProps} props
 * @returns {React.ReactElement}
 */
export const TextBody = (props) => {
  const { id, children, paragraph, bold, className, larger, smaller, ...rest } = props;
  const { classNames } = useView();
  const [controlUID] = useState(id ?? uniqueId(`lsn_ctrl_`));

  const elementProps = {
    id: controlUID,
    className: classNames('lsn-text-body', bold ? 'var-bold' : '', larger ? 'var-larger' : '', smaller ? 'var-smaller' : '', className),
    ...rest,
  };

  return paragraph ? <p {...elementProps}>{children}</p> : <span {...elementProps}>{children}</span>;
}

export default TextBody;

/**
 *
 * @param {TextBodyProps} props
 * @returns {React.ReactElement}
 */
export const TextParagraph = (props) => TextBody({ ...props, paragraph: true })

/**
 *
 * @param {Omit<TextBodyProps, 'bold'|'smaller'>} props
 * @returns {React.ReactElement}
 */
export const TextBadge = (props) => TextBody({
  ...props,
  bold: true,
  smaller: true,
  className: ['var-badge', props.className].filter(Boolean).join(' '),
});


/**
 *
 * @param {Omit<TextBodyProps, 'bold'|'smaller'|'paragraph'>} props
 * @returns {React.ReactElement}
 */
export const TextReset = (props) => TextBody({
  ...props,
  bold: false,
  smaller: false,
  paragraph: true,
  className: ['var-reset', props.className].filter(Boolean).join(' '),
});
