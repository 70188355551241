/**
 *
 * @param {Response} resp
 */
const responseType = (resp) => {
  const responseType = resp.headers.get('Content-Type');
  const isRespTypePlainText = responseType?.startsWith('text/');
  const isRespTypeJson = responseType?.startsWith('application/json');

  return {
    isRespTypePlainText,
    isRespTypeJson,
  }
};


/**
 *
 * @param {Response} resp
 * @returns {Promise}
 */
const responseDataResolver = (resp) => {
  const { isRespTypeJson, isRespTypePlainText } = responseType(resp);

  const reponseBodyResolver = isRespTypePlainText
    ? resp.text()
    : isRespTypeJson
      ? resp.json()
      : resp.blob();
  return reponseBodyResolver;
}

/**
 * @typedef {{ status:Response['status']; body:any; text:any; originResponse:Response; redirected:boolean; statusText:Response['statusText']; url:string; type:Response['type']}} TResponseObject
 *
 * @param {Promise<Response>} request
 * @returns {Promise<TResponseObject>}
 */
const responseBuilder = async (request) => {
  let resp;
  try {
    resp = await request;
  } catch (err) {
    resp = await Promise.reject(err);
  }
  const data = await Promise.all([resp, responseDataResolver(resp)]);
  const originResponse = data[0];
  const resolvedBody = data[1];
  const result = {
    status: originResponse.status,
    body: resolvedBody,
    text: responseType(originResponse).isRespTypePlainText ? resolvedBody : null,
    originResponse,
    ok: originResponse.ok,
    redirected: originResponse.redirected,
    statusText: originResponse.statusText,
    url: originResponse.url,
    type: originResponse.type,
  };
  return await (originResponse.ok ? Promise.resolve(result) : Promise.reject(result));
}

/**
 *
 * @param {string} endpoint
 * @param {Record<string, string|number>=} headers
 */
export const apiGET = (endpoint, headers = {}) => {
  const requestHeaders = {
    'Content-Type': 'application/json',
    ...headers,
  };
  return responseBuilder(fetch(endpoint, {
    headers: requestHeaders,
  }));
}
/**
 * @typedef {(endpoint:string,payload?:any, header?:Record<string,string|number>) => Promise} TAPIWithPayloadFunction
 *
 * @param {'POST'|'PUT'|'DELETE'|'PATCH'} method
 * @returns {TAPIWithPayloadFunction}
 */
const apiWithPayloadBuilder = (method = 'POST') => (endpoint, payload, headers = {}) => {
  const isPayloadTypeFormData = payload instanceof FormData;
  const isPayloadTypeString = payload instanceof String;
  const isPayloadTypeJson = typeof payload === 'object' && !isPayloadTypeString && !isPayloadTypeFormData;

  return responseBuilder(fetch(endpoint, {
    method,
    headers: {
      ...(isPayloadTypeFormData ? {} : { 'Content-Type': isPayloadTypeJson ? 'application/json' : 'text/plain' }),
      ...headers,
    },
    ...(typeof payload === 'undefined' ? {} : { body: isPayloadTypeJson ? JSON.stringify(payload) : payload }),
  }));
}

export const apiPOST = apiWithPayloadBuilder('POST');
export const apiPUT = apiWithPayloadBuilder('PUT');
export const apiDELETE = apiWithPayloadBuilder('DELETE');
