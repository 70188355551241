export const DEV_MODE = false;
export const IS_JEST = typeof jest !== 'undefined';

export const PHONE_INITIAL_COUNTRY_CODE = 'us';
export const PHONE_NUMBER_VALIDATION_MIN_LENGTH = 7;

export const SESSION_IDLE_TIME = 27 * 60 * 1000;
export const SESSION_ABOUT_EXPIRE_COUNTDOWN = 3 * 60;

export const MAX_VALID_FILE_SIZE = 15728640; //15MB
export const MAX_VALID_FILE_NAME_LENGTH = 95;

export const COMMENT_TEXT_LIMIT = 600; // chars
