// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-timeout-modal .modal-header .modal-title i {
  color: var(--main-red);
  padding-right: 0.1rem;
}
.session-timeout-modal .modal-header .modal-title .timer {
  margin-left: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/SessionTimeoutModal/SessionTimeoutModal.less"],"names":[],"mappings":"AAAA;EAIQ,sBAAA;EACA,qBAAA;AAFR;AAHA;EASQ,mBAAA;AAHR","sourcesContent":[".session-timeout-modal {\n  .modal-header {\n    .modal-title {\n      i {\n        color: var(--main-red);\n        padding-right: .1rem;\n      }\n\n      .timer {\n        margin-left: .5rem;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
